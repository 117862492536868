.feestruct h3{

    white-space: nowrap;

}




.fee-head{
    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 300px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    /* margin-bottom: 30px; */
    margin: 20px 30px;
    text-align: center;
}


/* .fee-content{
    width: 100%;
} */




@media (max-width: 768px) {

    .fee-head{
        width: 200px;
    }
    
}