/* General Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

/* Updates Section */
.updates {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #eeeeee;
}

.updates-parent {
  width: 100%;
  max-width: 1200px;
}

.updates-parent h3 {
  font-weight: 600;
  border: 3px solid #038681;
  background-color: #ffffff00;
  width: 250px;
  border-radius: 50px;
  padding: 9px 0;
  box-shadow: 0 10px #03868265;
  color: #038681;
  margin-bottom: 30px;
  text-align: center;
}

/* Arrow Buttons */
.arrow-buttons {
  margin-bottom: 20px;
}

.custom-arrow-btn {
  border: 3px solid;
  background-color: #007bff00;
  color: #038681;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 50%;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 10px #03868265;
}

.custom-arrow-btn:hover {
  background-color: #038681;
  color: white;
}

/* Poster Section */
.poster-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap; /* Ensures wrapping for smaller screens */
  padding: 16px;
}

.img-container {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 8px;
}

.img-container:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.poster-img {
  width: 150px; /* Fixed width */
  height: 200px; /* Fixed height */
  object-fit: cover; /* Ensures images maintain aspect ratio while fitting in the box */
  border-radius: 8px;
  transition: transform 0.2s ease-in-out;
}

.poster-img:hover {
  transform: scale(1.02); /* Small zoom on hover */
}

/* Lightbox Styles */
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  max-width: 70%;
  max-height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightbox-img {
  max-width: 60%;
  max-height: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .updates-parent h3{
    font-size: 1.5rem;
    width: 200px;
  }

  .custom-arrow-btn {
    padding: 8px 15px;
    font-size: 1rem;
  }

  .poster-container .img-container {
    flex: 1 1 100%;
  }

  .poster-img {
    width: 100%;
    height: auto;
  }

  .lightbox-img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

@media (min-width: 768px) {
  .poster-container .img-container {
    flex: 1 1 calc(33.333% - 20px);
  }

  .poster-img {
    width: 300px;
    height: 400px;
  }
}
