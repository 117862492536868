


.activity .table{
    width: 60%;
}


.activity h2{
    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 700px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    margin-bottom: 30px;
    text-align: center;
}


.activity .btn{
    /* font-weight: 600; */
    border: 3px solid #038681;
    background-color: #ffffff;
    /* width: 450px; */
    border-radius: 50px;
    padding: px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    margin-bottom: 30px;
    text-align: center;
}

@media (max-width: 576px) {
    .activity .table{
        width: 100%;
    }

    .activity h2{
        font-size: 1.2rem;
        width: 390px;
    }
    
}