
.PO-parent p{
    text-align: justify;
}


.PO-text{
    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 450px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    margin-bottom: 30px;
    text-align: center;
}


@media (max-width: 576px) {

    .PO-text{
        width: 300px;
        font-size: 1.3rem;

    }
    
}