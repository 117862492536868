.header-parent {
    padding-top: 5px;
    background-color: #f8f9fa; /* Optional: light gray background */
}

.logo {
    max-width: 200px;
    height: auto;
}


.text-content{
    overflow: hidden;
}

.text-content h2, h3, h4, h5 {
    margin: 5px 0;
}



/* Header.css */

/* Responsive font sizes for different screen widths */
.fs-small {
    font-size: 15px; /* Base font size for small screens */
}
  
.fs-medium {
    font-size: 40px; /* Medium font size for larger screens */
}
  
.fs-smaller {
    font-size: 20px; /* Large font size for extra-large screens */
}

@keyframes slideNotif {
    0% {
      transform: translateX(-70%);
    }
    100% {
      transform: translateX(70%);
    }
  }
  
  .slide-notif {
    display: inline-block; /* Ensures proper animation */
    white-space: nowrap;   /* Prevents text wrapping */
    animation: slideNotif 15s linear infinite; /* Smooth and infinite sliding */
    position: relative;
    font-size: 20px;
    overflow: hidden;
    /* background-color: aqua; */
    /* width: 50%; */
  }


@media (max-width: 992px) {

    /* Responsive font sizes for different screen widths */
.fs-small {
    font-size: 15px; /* Base font size for small screens */
}
  
.fs-medium {
    font-size: 30px; /* Medium font size for larger screens */
}
  
.fs-smaller {
    font-size: 15px; /* Large font size for extra-large screens */
}

.slide-notif{
    font-size: 15px;
}
    
}


@media (max-width: 768px) {


    .logo{
        width: 100px;
    }

    /* Responsive font sizes for different screen widths */
.fs-small {
    font-size: 10px; /* Base font size for small screens */
}
  
.fs-medium {
    font-size: 20px; /* Medium font size for larger screens */
}
  
.fs-smaller {
    font-size: 10px; /* Large font size for extra-large screens */
}

.blink{
    font-size: 15px;
    /* overflow: hidden; */
}
    
}


@keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  .blink {
    /* font-size: 15px; */
    animation: blink 2s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(180px); /* Move right */
    }
    50% {
      transform: translateX(0); /* Back to center */
    }
    75% {
      transform: translateX(-180px); /* Move left */
    }
  }
  
  .bounce {
    animation: bounce 2s infinite ease-in-out; /* Smooth looping animation */
  }
  

  
  