.guest-text{
    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 550px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    margin-bottom: 30px;
    text-align: center;
}



.guest .btn{
    /* font-weight: 600; */
    border: 3px solid #038681;
    background-color: #ffffff;
    /* width: 450px; */
    border-radius: 50px;
    padding: px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    margin-bottom: 30px;
    text-align: center;
}


.guest .table{
    width: 60%;
}



@media (max-width: 768px) {
    .guest-text{
        width: 350px;
    }



    .guest .table{
        width: 100%;
    }
    
}