


.tres-img{
    width: 400px;
}






.fs-p{
    font-size: 18px;
    text-align: justify;
}