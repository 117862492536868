.about {
    background-color: #fffef5;
    margin-top: 30px;
    margin-bottom: 30px;

}



/* Section title */
.aboutus {

    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 200px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;

}


.text-content p {
    line-height: 1.8;
    color: #333;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
}

/* .image-content{
    height: 450px;
}


.image-content img {
    max-width: 100%;
    height: auto;
} */

@media (max-width: 768px) {

    .text-content p {
        font-size: 0.95rem;
    }

    .image-content{
        height: 150px;
        margin-bottom: 80px;
    }
}

@media (max-width: 576px) {

    .aboutus {
        font-size: 1.5rem;
        width: 150px;
    }

    .text-content p {
        font-size: 0.9rem;
    }
}
