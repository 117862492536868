

.ad .table{
    width: 60%;
}

.adb-text{
    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 330px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    margin-bottom: 30px;
    text-align: center;
}


.bs-text{
    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 350px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    margin-bottom: 30px;
    text-align: center;
}

.bs-table{
    width: 900px;
}



@media (max-width: 576px) {
    .adb-text{
        width: 250px;
    }
    .ad .table{
        width: 100%;
    }
    .bs-table{
        width: 100%;
    }
}