
.footer{
    background-color: #e9e9e9;
    /* margin-top: 20px; */
}
.footer-parent{
    padding-top: 40px;
    padding-bottom: 20px;
}
.footer-parent .logo{
    max-width: 150px;
}


.footer-parent h2{
    font-weight: 600;
}

.footer-parent p{
    color: rgb(0, 0, 0);
    text-align: justify;
}

.footer-parent a{
    color: black;
    
}


.footer-head-text{
    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 300px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    /* margin-bottom: 30px; */
    margin: 20px 0px;
    text-align: center;
}


.oath p{
    text-align: justify;
}

.useful-links a {
    font-size: 1.2rem;
    color: #333; /* Neutral color for links */
    text-decoration: none;
    padding: 10px 0; /* Spacing between links */
    border-bottom: 1px solid #038681; /* Divider between links */
    transition: color 0.3s, background-color 0.3s; /* Smooth hover effects */
}

.useful-links a:last-child {
    border-bottom: none; /* Remove divider after the last link */
}

.useful-links a:hover {
    color: #2E8B57; /* Custom green on hover */
    background-color: #f9f9f9; /* Light background highlight on hover */
    border-radius: 4px; /* Rounded edges for hover effect */
}





.contact-text p span{
    font-weight: 500;
}



.footer-bottom img{
    max-width: 60px;
}




/* Social Icon Styles */
.social-icons {
    margin-top: 20px; /* Add spacing above the icons */
    gap: 15px; /* Space between icons */
}

.social-link {
    color: #333; /* Neutral color for icons */
    margin: 0 10px; /* Space around individual icons */
    transition: color 0.3s ease-in-out; /* Smooth hover transition */
    text-decoration: none; /* Remove underline */
}

.social-link i {
    transition: transform 0.3s ease-in-out; /* Smooth zoom effect on hover */
    font-size: 2rem;
}

.social-link:hover {
    color: #2E8B57; /* Custom green color on hover */
}

.social-link:hover i {
    transform: scale(1.2); /* Slightly enlarge icon on hover */
}


.follow{
    margin: 0 auto;
}


@media (max-width: 768px) {

    .footer-bottom img{
        max-width: 50px;
    }

    .footer-parent .logo{
        max-width: 150px;
    margin: 0 auto;

    }

    .footer-head-text{
        width: 220px;
    }

    .useful-links h2{
        margin: 20px auto;
    } 
    
    .contact-footer h2{
        margin: 20px auto;
    }
    .oath h2{
        margin: 20px auto;
    }
    
}