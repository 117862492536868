.cdc .table{
    width: 60%;
}
.cdc .cdc-text{
    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 800px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    margin-bottom: 30px;
    text-align: center;
}

.COC-text{
    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 300px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    margin-bottom: 30px;
    text-align: center;
}

@media (max-width: 992px) {
    .cdc .table{
        width: 100%;
    }


    
}


@media (max-width: 576px) {

    .cdc .cdc-text{
        width: 350px;
        font-size: 1.5rem;

    }
    .COC-text {
        width: 220px;
    }
}