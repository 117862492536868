

.course-head{
    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 300px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    /* margin-bottom: 30px; */
    margin: 20px 30px;
    text-align: center;
}



.course-content{
    width: 100%;
}



@media (max-width: 768px) {

    .course-head{
        width: 220px;
    }
    
}