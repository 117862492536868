/* General Styles */
body {
    margin: 0;
    /* font-family: 'Arial', sans-serif; */
}

.container-fluid {
    padding: 0;
}

.contact iframe{
    width: 850px;
}

.contact-text {
    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 250px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    margin-bottom: 30px;
    text-align: center;
}

.text-custom-green {
    color: #038681;
}

/* .text-custom-blue {
    color: #007bff;
} */

/* Social Links */
a i {
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

a i:hover {
    transform: scale(1.2);
    color: #038681;
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-text {
        font-size: 2rem;
    }

    .contact iframe {
        width: 95%;
        height: 250px;
    }

    .text-custom-blue,
    .text-custom-green {
        text-align: center;
    }

    .d-flex {
        justify-content: center;
    }
}



@media (max-width: 576px) {
    .contact-text{
        width: 200px;
    }
    
}