.presi-text {
    background-color: rgba(255, 233, 39, 0);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.presi-text .from, 
.presi-text .quote, 
.presi-text .name {
    font-weight: 700;
    margin-top: 5px;
    /* white-space: nowrap; */
}


.presi-text .post{
    margin-top: -7px;
}


.presi-text .ad{
    white-space: nowrap;
}





.presi-img {
    background-color: rgba(0, 255, 255, 0);
    display: flex;
    align-items: center;
    justify-content: center; /* Ensure centering */
}

.presi-img img {
    width: 100%; /* Adjust width for responsiveness */
    max-width: 280px; /* Maintain original size on larger screens */
    border: 4px solid #038681;
    padding: 10px;
}

.post {
    font-size: 1.6rem; /* Use relative units */
    font-weight: 600;
}

/* --- Responsive Design --- */

/* For tablets and smaller screens (768px or less) */
@media (max-width: 768px) {
    .presi-text {
        align-items: center; /* Center text alignment on smaller screens */
        text-align: center;
    }

    .presi-img img {
        max-width: 200px; /* Reduce image size for tablets */
    }
    .presi-text .quote {
        white-space: nowrap; /* Keep it on one line */
        font-size: 18px;
    }

    .prin-text .quote{
        white-space: wrap;
    }

    .post {
        font-size: 1.4rem; /* Adjust text size */
    }
}

/* For mobile screens (480px or less) */
@media (max-width: 480px) {
    .presi-text {
        gap: 5px; /* Add spacing between elements */
    }

    .presi-img {
        flex-direction: column; /* Stack image and text vertically */
        gap: 10px; /* Add spacing between elements */
    }

    .presi-img img {
        max-width: 250px; /* Further reduce image size */
    }

    .post {
        font-size: 1.2rem; /* Smaller text for mobile */
    }
}
