


.vismis{
    background-color: #038681;
}

.visionmission h4{
    border: 3px solid white;
    padding: 5px 18px;
    border-radius: 50px;
  box-shadow: 0 10px #e1fdfc65;

}

.visionmission p{
    font-size: 20px;
}