.govbody .table{
    width: 60%;
}


.gov-text{
    font-weight: 600;
    border: 3px solid #038681;
    background-color: #ffffff00;
    width: 550px;
    border-radius: 50px;
    padding: 9px 0;
    box-shadow: 0 10px #03868265;
    color: #038681;
    margin-bottom: 20px;
    text-align: center;
}

@media (max-width: 992px) {
    .govbody .table{
        width: 100%;
    }
    
}


@media (max-width: 576px) {

    .gov-text{
        width: 320px;
        font-size: 1.3rem;
    }
    
}

