/* General Navbar Styles */
.navbar {
  padding: 0;
  margin: 0;
  background-color: gold; /* Custom green background */
}
.navbar-toggler i {
  background-color: rgba(0, 0, 0, 0); /* Set the color to black */
  color: #038681;
  /* outline: none; */
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none;
  border: none;
  box-shadow: none; /* Remove any box shadow that might appear as a border */
}


.navbar .container {
  max-width: 1200px;
  margin: 0 auto;
}
 
/* Navbar Items */
.navbar-nav {
  display: flex;
  justify-content: center;
  width: 100%;
}

.navbar-nav .nav-item {
  margin: 0 20px; /* Spacing between navbar items */
}

.navbar-nav .nav-link {
  color: #000000;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.3s ease-in-out;
  text-decoration: none;
}

.navbar-nav .nav-link:hover {
  color: #038681;
}

/* Dropdown Menu Styles */
.nav-item.dropdown {
  position: relative;
}


.navbar-nav .dropdown-menu {
  display: none; /* Hidden by default */
  /* position: absolute;
  top: 100%;
  left: 0;
  right: 0; */
  background-color: #ffffff;
  /* border: 1px solid #1990ec; */
  border-top: 4px solid gold;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  width: auto;
}

.navbar-nav .dropdown-menu.show {
  display: block; /* Show the dropdown when the class 'show' is applied */
}

/* Show dropdown menu on hover */
.nav-item.dropdown:hover .dropdown-menu {
  display: block; /* Make dropdown visible */
}


.navbar-nav .dropdown-item {
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
}

.navbar-nav .dropdown-item:hover {
  background-color: #f1f1f1;
  color: #007bff;
}

/* Ensure dropdown doesn't overlap navbar items */
.nav-item.dropdown .dropdown-menu {
  left: 0;
  right: auto;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  outline: none;
}

/* .navbar-toggler-icon {
  background-color: #f6747400;
} */

@media (max-width: 992px) {
  .navbar-nav {
    flex-direction: column;
    align-items: center;
  }

  .navbar-toggler{
    font-size: 35px;
  }

  .navbar-nav .nav-item {
    margin: 10px 0; /* Spacing between items on smaller screens */
  }

  .navbar-nav .dropdown-menu {
    width: 100%; /* Full width dropdown */
    left: 0;
    right: 0;
  }

  .navbar-nav .dropdown-item {
    padding: 12px 20px; /* Adjust dropdown item padding */
  }
}

/* Mobile-specific Adjustments */
@media (max-width: 576px) {
  .navbar-nav .nav-item {
    font-size: 14px; /* Smaller font size on mobile */
  }

  .navbar-nav .nav-link {
    padding: 10px;
  }

  .navbar-nav .dropdown-item {
    padding: 12px;
  }
}
