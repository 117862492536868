.carousel-inner img{
    height: 80vh;
    object-fit: cover;
}


@media (max-width: 768px) {
    .carousel-inner img{
        height: 30vh;
    }
    
}