/* Container for the notification banner */
.notification-container {/* Adjust as needed */
    width: 100%;
    overflow: hidden;
    z-index: 9999;
    background-color: #038681;
  }
  
  /* Scrolling content */
  .notification-content {
    display: flex;
    align-items: center;
    animation: scroll-left 30s linear infinite; /* Adjust duration as needed */
    white-space: nowrap;
    animation-play-state: running; /* Default state */
  }
  
  /* Hover effect to pause animation */
  .notification-container:hover .notification-content {
    animation-play-state: paused;
  }
  
  /* Text content */
  .notification-text {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .notification-text a{
    color: white;
  }
  
  /* Blinking image */
  .blinking-image {
    width: 60px; /* Adjust size */
    height: 40px;
  }
  
  /* Links */
  .notification-link {
    text-decoration: none;
    color: #007bff; /* Bootstrap primary color */
    font-weight: bold;
    margin: 0 5px;
  }
  
  .notification-link:hover {
    text-decoration: underline;
  }
  
  /* Separator */
  .separator {
    color: #6c757d; /* Bootstrap secondary color */
  }
  
  /* Keyframes for scrolling */
  @keyframes scroll-left {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  

  